export const ROUTES = {
  LOGIN: "/login",
  AUTH: "/auth",
  AUTHL: "/authl",
  DASHBOARD: "/",

  PATIENTS: "/patients",
  PATIENTSDETAIL: "/patients/:patientId",

  CAREPROVIDERS: "/careproviders",
  CAREPROVIDERSDETAIL: "/careproviders/:careproviderId",

  BOOKINGSDETAIL: "/bookings/:bookingId",

  COURSESESSIONS: "/coursesessions",
  COURSESESSIONSDETAIL: "/coursesessions/:coursesessionId",

  TRAINING: "/training",

  TRIALANALYSIS: "/reports/trialanalysis",
  BOOKINGS: "/reports/bookings",
  TRIALSLOTS: "/reports/trialslots",
  REPORTS: "/reports",

  HOME: "/",

  LABS: "/tech",
  APPVERSION: "/tech/appversion",
};
